<template>
  <div>
    <div v-if="canAccess('matriculas_comision_read')" class="p-grid">
      <div class=" p-col-12 card card-w-title">
        <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items"/>
        <Toolbar class="p-col-12">
          <template #left>
            <div class="p-grid p-fluid filter p-ml-2">

              <div class="p-field   p-fluid  p-mr-2">
                                    <span class="p-float-label df">
                                       <Dropdown v-model="filters.status" :options="status" @change="filter"/>
                                        <label for="status">Estado</label>
                                    </span>
              </div>
              <div class="p-field   p-fluid  p-mr-2" v-if="isAdmin">
                                    <span class="p-float-label df">
                                       <Dropdown v-model="selectedC" :options="countries" optionLabel="country"
                                                 @change="selectedM=null;selectedW=null;filter()"/>
                                        <label for="country">País</label>
                                    </span>
              </div>
              <div class="p-field   p-fluid  p-mr-2">
                                    <span class="p-float-label df">
                                       <Dropdown v-model="selectedM" :options="months" optionLabel="month_process"
                                                 @change="selectedW=null;filter()"/>
                                        <label for="months">Mes</label>
                                    </span>
              </div>
              <div class="p-field   p-fluid  p-mr-2">
                                    <span class="p-float-label df">
                                       <Dropdown v-model="selectedW" :options="weeks" optionLabel="week_process"
                                                 @change="filter"/>
                                        <label for="weeks">Semana</label>
                                    </span>
              </div>
              <div class="p-field   p-fluid">
                                    <span class="p-float-label">
                                        <Calendar :showIcon="true" v-model="filters.from" dateFormat="yy-mm-dd"
                                                  :yearNavigator="true"
                                                  @date-select="filter" yearRange="2000:2050" class="p-inputtext-sm"/>
                                        <label for="start_date">Creado desde</label>
                                    </span>
              </div>
              <div class="p-field   p-fluid  p-ml-2">
                                    <span class="p-float-label">
                                        <Calendar :showIcon="true" v-model="filters.to" dateFormat="yy-mm-dd"
                                                  :yearNavigator="true"
                                                  @date-select="filter" yearRange="2000:2050" class="p-inputtext-sm"/>
                                        <label for="end_date">Creado hasta</label>
                                    </span>
              </div>
              <div class="p-field   p-fluid  p-ml-2">
                                    <span class="p-float-label">
                                        <InputText v-model="search" @keyup.enter="filter"/>
                                        <label for="enroll">Buscar por</label>
                                    </span>
              </div>
              <div class="p-field   p-fluid  p-ml-2">
                <Button label="Limpiar" icon="pi pi-undo" class="p-button-secondary p-mr-2"
                        @click="reload"/>
              </div>

            </div>
          </template>
        </Toolbar>
      </div>
      <DataTable :value="pEnrollments" :paginator="true" dataKey="id"
                 v-model:selection="currentItem" selectionMode="single"
                 @row-select="selectData($event)"
                 paginatorPosition="bottom"
                 :lazy="true"
                 :totalRecords="page.total"
                 :rows="page.per_page"
                 :loading="loading"
                 @page="onPage($event)">

        <Column header="Matrícula #" headerStyle="text-align: center;">
          <template #body="slotProps">
            <div class="center">
              <router-link :to="'/commission/detail/'+slotProps.data.id">{{
                  slotProps.data.enrollment?.sequential
                }}
              </router-link>
            </div>
          </template>
        </Column>
        <Column header="Nombres Titular" headerStyle="text-align: center;">
          <template #body="slotProps">
            <div class="center">{{ slotProps.data.enrollment?.contract?.holder?.name }}
              {{ slotProps.data.enrollment?.contract?.holder?.last_name }}
            </div>
          </template>
        </Column>
        <Column header="Id Titular" headerStyle="text-align: center;">
          <template #body="slotProps">
            <div class="center">{{ slotProps.data.enrollment?.contract?.holder?.id }}</div>
          </template>
        </Column>
        <Column header="Plan Pagos" headerStyle="text-align: center;">
          <template #body="slotProps">
            <div class="center">{{ slotProps.data.enrollment?.plan?.name }}</div>
          </template>
        </Column>
        <Column header="Fecha Registro" field="date_payment" headerStyle="text-align: center;">
          <template #body="slotProps">
            <div class="center">{{ $filters.dateFormat(slotProps.data.enrollment?.registration) }}</div>
          </template>
        </Column>
        <Column header="Fecha Entrega Materiales" field="delivery_date_materials" headerStyle="text-align: center;">
          <template #body="slotProps">
            <div class="center">{{ $filters.dateFormat(slotProps.data.enrollment?.contract?.delivery_date_materials) }}</div>
          </template>
        </Column>
        <Column header="Mes/Semana"  headerStyle="text-align: center;">
          <template #body="slotProps">
            <div class="center">{{slotProps.data.liq_month?.month_process}} - {{slotProps.data.liq_week?.week_process}}</div>
          </template>
        </Column>
        <Column header="Estado" headerStyle="text-align: center;">
          <template #body="slotProps">
            <div class="center">
              <Tag class="p-mr-2" rounded icon="pi pi-check" severity="success" :value="slotProps.data.status"
                   v-if="slotProps.data.status === approvedKey"></Tag>
              <Tag class="p-mr-2" rounded icon="pi pi-check" severity="warning" :value="slotProps.data.status"
                   v-else-if="slotProps.data.status === generatedKey"></Tag>
              <Tag class="p-mr-2" rounded icon="pi pi-dollar" severity="success" :value="slotProps.data.status"
                   v-else-if="slotProps.data.status === payedKey"></Tag>
              <Tag class="p-mr-2" rounded icon="pi pi-times" severity="danger" :value="slotProps.data.status"
                   v-else-if="slotProps.data.status === denyKey"></Tag>
              <Tag class="p-mr-2" rounded icon="pi pi-times" severity="warning" :value="slotProps.data.status"
                   v-else-if="slotProps.data.status === postergedKey"></Tag>
              <Tag class="p-mr-2" rounded icon="pi pi-info-circle" :value="slotProps.data.status"
                   v-else-if="slotProps.data.status === newKey"></Tag>
              <!-- <Tag class="p-mr-2" rounded icon="pi pi-info-circle" v-else :value="slotProps.data.status"></Tag> -->
            </div>
          </template>
        </Column>

        <Column header="Estado pago"  headerStyle="text-align: center;">
          <template #body="slotProps">
            <div class="center">
              <Tag class="p-mr-2" rounded icon="pi pi-check" severity="success" :value="'Pagada'"
                   v-if="slotProps.data.status === 'Pagada'"></Tag>
              <Tag class="p-mr-2" rounded icon="pi pi-check" severity="warning" :value="'No Pagada'"
                   v-else-if="slotProps.data.status !== 'Pagada'"></Tag>
              <!-- <Tag class="p-mr-2" rounded icon="pi pi-info-circle" v-else :value="slotProps.data.status"></Tag> -->
            </div>
          </template>
        </Column>

      </DataTable>
    </div>

    <div v-else>
      <h5 class="card-header text-center"><b>ATENCIÓN!!</b></h5>
      <div class="card-body">
        <p class="card-title">El acceso a esta opción no se encuentra autorizado</p>
        <Button label="Regresar" class="p-button-danger p-mr-2"
                @click="$router.push('/admin')"/>
      </div>

    </div>


  </div>

</template>

<script>
import service from '../../service/processedEnrollments.service'
import mw from '../../service/mw.service'
import {mapGetters, mapState} from "vuex";
import moment from "moment";
import bouncer from "../../../../helpers/bouncer";

export default {
  mixins: [bouncer],
  name: "CommissionList",
  props: {
    enroll: Object,
    edit: Boolean,
  },
  data() {
    return {
      filtersMW: [],
      columns: [
        {field: 'code', header: 'Codigo'},
        {field: 'date_payment', header: 'Fecha'},
      ],
      page: {
        page: 1,
        per_page: 9,
        total: 0,
        order: "desc"
      },
      allKey: 'Todos',
      newKey: 'Nuevo',
      approvedKey: 'Aprobada',
      denyKey: 'Desaprobada',
      generatedKey: 'Generado',
      postergedKey: 'Postergada',
      payedKey: 'Pagada',
      pEnrollments: [],
      data: {},
      filters: {'status': 'Todos'},
      submitted: false,
      loading: false,
      editDialog: false,
      verifyDataDialog: false,
      selectedC: null,
      selectedM: null,
      selectedW: null,
      search: '',
      countries: [],
    }
  },
  methods: {
    onPage(event) {
      this.page.page = event.page + 1;
      this.filter()
    },
    changeW() {
      this.weeks = this.selectedM.weeks
      this.selectedW = null
      this.filter()
    },
    getData(params = {}) {
      this.loading = true;
      const config = {
        ...this.page,
        ...params
      };
      mw.getMWLiq().then(x => {
        this.countries = x.data
        if (!this.isAdmin) {
          this.selectedC = this.countries[0];
        }
      })
      service.get(null, config).then(x => {
        this.pEnrollments = x.data.data;
        this.page.total = x.data.total;
      }).finally(() => this.loading = false)


    },
    reload() {
      this.filters = {};
      this.filters = {'status': 'Todos'};
      this.search = '';
      this.getData();
    },
    // filterP(){
    //     this.getData()
    //     this.page.page = 1;
    //     this.filter()
    // },
    filter() {
      this.loading = true;
      const from = this.filters.from ? moment(this.filters.from).format("YYYY-MM-DD") : undefined;
      const to = this.filters.to ? moment(this.filters.to).format("YYYY-MM-DD") : undefined;
      const status = this.filters.status !== 'Todos' ? this.filters.status : undefined;
      const q = this.search !== '' ? this.search : undefined;
      var params = {
        q, from, to, status
      };

      if (this.selectedC !== null) {
        params = {
          q, from, to, status,
          ...{'country_id': this.selectedC.id}
        }
      }
      if (this.selectedW !== null) {
        params = {
          q, from, to, status,
          ...{'week': this.selectedW.id}
        }
      } else if (this.selectedM !== null) {
        params = {
          q, from, to, status,
          ...{'month': this.selectedM.id}
        }
      }

      this.getData(params);
    },
    selectData() {
      this.$router.push('detail/' + this.currentItem.id,);
    },
  },
  computed: {
    ...mapState('auth', ['isAdmin']),
    ...mapGetters('bread', ['breadcrumb']),
    status() {
      return [this.allKey,
        this.newKey,
        this.approvedKey,
        this.denyKey,
        this.generatedKey,
        this.postergedKey,
        this.payedKey]
    },
    months() {
      const result= this.selectedC ? this.selectedC.months : [];
      if(result.length===0 && this.selectedC){
        this.$toast.add({severity: 'warn', summary: 'Atención', detail: 'No existen meses asociados al país', life: this.$utils.toastLifeTime()});
      }
      return result;
    },
    weeks() {
      const result= this.selectedM ? this.selectedM.weeks : [];
      if(result.length===0  && this.selectedM){
        this.$toast.add({severity: 'warn', summary: 'Atención', detail: 'No existen semanas asociadas al mes', life: this.$utils.toastLifeTime()});
      }
      return result;
    }


  },
  mounted() {
    if (this.canAccess('matriculas_comision_read')) {
      this.$store.dispatch('bread/setItems', [
        {label: 'Registro', url: "javascript:void(0);"},
        {label: 'Lista de Matrículas con Comisión', url: "javascript:void(0);"},
      ]);
      this.getData();
    }
  }
}
</script>

<style scoped lang="scss">

.p-fieldset {
  margin-top: 15px;
}

.p-field {
  padding-top: 15px;
}

.p-dialog-footer {
  padding: 0;
  margin-bottom: -15px;
}

.p-grid {
  margin-top: 15px;
}

.p-toolbar {
  background: none;
  border: none;
}

.p-inputtext {
  width: 100%;
}

.p-autocomplete {
  height: 30px !important;
}

.df, #status, #months, #weeks, #countries {
  height: 100%;
}

.p-selectable-row td:first-child, .uc {
  padding-left: 1.5rem !important;
  display: inline-block;
}

.center {
  text-align: center;
}
</style>
